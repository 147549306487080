<template>
  <div>
    <BaseModal size="lg" v-bind="$attrs" v-on="$listeners">
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseSearchCondition
            :searchCondition="searchCondition"
            :searchConditionInfo="searchConditionInfo"
            @clear="clear"
            @search="search"
          >
          </BaseSearchCondition>
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import { DEPARTMENT_KIND } from '@/common/const.js'
import { getListOptions, openSubModal } from '@/common/Common.js'

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
  },
  props: {
    periodDateFrom: {
      type: String,
      default: null,
    },
    periodDateTo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      clickDepartmentModal: -1,
      clickPlaceModal: -1,
      departmentModalSearchCondition: {},
    }
  },

  computed: {
    locale() {
      let lang = this.$store.getters['authentication/getLanguage']
      return lang
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.UNASSIGNED_ORDER_SEARCH
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 期間
            type: 'select',
            id: 'period',
            columnInfo: searchConditionInfo.period,
            options: getListOptions(this.MASTER_CODE.PERIOD_CODE),
            rules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 期間
            type: 'dateFromTo',
            fromId: 'periodDateFrom',
            toId: 'periodDateTo',
            fromColumnInfo: searchConditionInfo.period_date_from,
            toColumnInfo: searchConditionInfo.period_date_to,
            locale: this.locale,
            fromRules: [
              this.INPUT_TYPE_CHECK.REQUIRED,
              this.INPUT_TYPE_CHECK.DATETIMERANGE,
            ],
            toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 受付番号
            type: 'text',
            id: 'receiptNo',
            columnInfo: searchConditionInfo.receipt_no,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            maxLength: 30,
          },
          {
            // 配送纏めID
            type: 'text',
            id: 'deliveryPackupId',
            columnInfo: searchConditionInfo.delivery_packup_id,
            rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            maxLength: 15,
          },
          {
            // 荷主
            type: 'referenceCodeName',
            codeId: 'ownerCode',
            nameId: 'ownerName',
            codeColumnInfo: searchConditionInfo.owner_code,
            nameColumnInfo: searchConditionInfo.owner_name,
            btnColumnInfo: searchConditionInfo.sub_modal_owner,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.clickDepartmentModal = 0
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.OWNER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 荷積場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.clickPlaceModal = 0
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 荷卸場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.clickPlaceModal = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 特約店
            type: 'referenceCodeName',
            codeId: 'distributorCode',
            nameId: 'distributorName',
            codeColumnInfo: searchConditionInfo.distributor_code,
            nameColumnInfo: searchConditionInfo.distributor_name,
            btnColumnInfo: searchConditionInfo.sub_modal_distributor,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.clickDepartmentModal = 1
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.DISTRIBUTOR
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 荷受先
            type: 'referenceCodeName',
            codeId: 'consigneeCode',
            nameId: 'consigneeName',
            codeColumnInfo: searchConditionInfo.consignee_code,
            nameColumnInfo: searchConditionInfo.consignee_name,
            btnColumnInfo: searchConditionInfo.sub_modal_consignee,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.clickDepartmentModal = 2
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CONSIGNEE
              this.openSubModal(this, 'department-modal')
            },
          },
          // {
          //   // 配車計画グループ
          //   type: 'checkboxgroup',
          //   id: 'planningGroupCode',
          //   columnInfo: searchConditionInfo.planning_group_code,
          //   options: getListOptions(this.MASTER_CODE.PLANNING_GROUP, false),
          // },
          {
            // 摘要01
            type: 'text',
            id: 'description01',
            columnInfo: searchConditionInfo.description01,
          },
          {
            // 摘要02
            type: 'text',
            id: 'description02',
            columnInfo: searchConditionInfo.description02,
          },
          {
            // 摘要03
            type: 'text',
            id: 'description03',
            columnInfo: searchConditionInfo.description03,
          },
          {
            // 摘要04
            type: 'text',
            id: 'description04',
            columnInfo: searchConditionInfo.description04,
          },
          {
            // 摘要05
            type: 'text',
            id: 'description05',
            columnInfo: searchConditionInfo.description05,
          },
          {
            // 荷姿名
            type: 'text',
            id: 'productName',
            columnInfo: searchConditionInfo.product_name,
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.UNASSIGNED_ORDER_SEARCH
      )('result_ctrl_info')
    },
  },

  methods: {
    getListOptions,
    openSubModal,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        period: this.PERIOD.DEPARTURE,
        periodDateFrom: this.periodDateFrom,
        periodDateTo: this.periodDateFrom,
        receiptNo: null,
        deliveryPackupId: null,
        ownerCode: null,
        ownerName: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        distributorCode: null,
        distributorName: null,
        consigneeCode: null,
        consigneeName: null,
        planningGroupCode: [],
        description01: null,
        description02: null,
        description03: null,
        description04: null,
        description05: null,
        productName: null,
        planningStatus: this.PLANNING_STATUS.NOT_ASSIGNED,
        requestId: null, //String Id
        planningOrderIdList: null, //String list Id
      }
    },

    search() {
      this.$bvModal.hide('unassigned-order-search') //unassigned-order-search-modal')
      this.$emit('after-close-set', this.searchCondition)
    },

    clear() {
      this.searchCondition = this.defaultSearchCondition()
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      switch (this.clickDepartmentModal) {
        case 0:
          this.searchCondition.ownerCode = code
          this.searchCondition.ownerName = name
          break
        case 1:
          this.searchCondition.distributorCode = code
          this.searchCondition.distributorName = name
          break
        case 2:
          this.searchCondition.consigneeCode = code
          this.searchCondition.consigneeName = name
          break

        default:
          break
      }
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      switch (this.clickDepartmentModal) {
        case 0:
          this.searchCondition.ownerCode = null
          this.searchCondition.ownerName = null
          break
        case 1:
          this.searchCondition.distributorCode = null
          this.searchCondition.distributorName = null
          break
        case 2:
          this.searchCondition.consigneeCode = null
          this.searchCondition.consigneeName = null
          break

        default:
          break
      }
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      switch (this.clickPlaceModal) {
        case 0:
          this.searchCondition.departurePlaceCode = selectedItem.place_code
          this.searchCondition.departurePlaceName = selectedItem.place_name
          break
        case 1:
          this.searchCondition.arrivalPlaceCode = selectedItem.place_code
          this.searchCondition.arrivalPlaceName = selectedItem.place_name
          break

        default:
          break
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      switch (this.clickPlaceModal) {
        case 0:
          this.searchCondition.departurePlaceCode = null
          this.searchCondition.departurePlaceName = null
          break
        case 1:
          this.searchCondition.arrivalPlaceCode = null
          this.searchCondition.arrivalPlaceName = null
          break

        default:
          break
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
